.my-button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  background-color: #a435f0;
}
/* or */
/* .my-button { all: unset; } */ 

.my-button:focus-visible { outline: 1px solid var(--your-color); }

.form-group {
  margin-bottom: 2vh;
}

.form-label {
  font-weight: 600;
  margin-bottom: 1vh;
}

.form-input {
    width: 100%;
    height: 50px;
    font: italic normal normal 14px/16px Arial;
    letter-spacing: 0px;
    color: #929292;
    opacity: 1;
    line-height: 20px;
    padding: 6px 20px;
    border-radius: 4px;
    font-style: italic;
    box-sizing: border-box;
}

.form-input:focus {
  outline: 0;
  /* color: #303030;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 133, 255, 0.25); */
}

.modal-title {
  font-size: 24px;
  font-weight: 700;
  color: #e00000;
}

.modal-content {
  color: #333;
  line-height: 21px;
}

.modal-button {
  padding: 10px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #0041f0;
}

.pdf-pagination {
  display: flex;
  align-items: center;
}

.button-group {
  flex: 12% 0;
}

.page-text-box {
  width: 30px;
  text-align: center;
}

.annotation-page-number {
  margin: 10px 5px;
}

.pagination-arrow {
  cursor: pointer;
  font-size: 28px;
  margin: 10px 5px;
  font-weight: 600;
}

.pagination-arrow-disable {
  font-size: 28px;
  cursor: default;
  font-weight: 600;
  margin: 10px 5px;
  color: rgba(0, 0, 0, 0.24);
}

.pdfloader-root {
  z-index: 100;
  display: block;
  margin: 0 auto;
}

.link {
  color: white;
  text-decoration: underline;
}

.link:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.modal-title-feedback {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  box-sizing: border-box;
  padding: 16px !important;
  background-color: #F2F2F2;
}

.modal-title-feedback-club {
  display: flex;
  align-items: center;
}

.modal-title-feedback-image {
  margin-right: 2%;
}

.modal-button-feedback {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  color: #ffffff;
  line-height: 18px;
  border-radius: 25px;
  box-sizing: border-box;
  background-color: #7500C0;
}

.modal-button-feedback-disabled {
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  line-height: 18px;
  border-radius: 25px;
  pointer-events: none;
  box-sizing: border-box;
  background-color: #939393;
}

.modal-content-feedback {
  color: #1D1823;
  line-height: 16px;
}

.modal-content-textarea {
  padding: 5px;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  box-sizing: border-box;
  border-color: #CCCBCE;
  color: #837F89 !important;
}

.modal-content-textarea:focus {
  outline: 0;
}

.modal-content-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Graphik";
}

.modal-foooter-loader {
  height: 7vh;
  display: flex;
  margin-right: 1%;
  justify-content: flex-end;
}



.textLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensure it fills the page */
  height: 100%;
  pointer-events: none; /* Prevent it from blocking clicks */
  overflow: hidden;
  opacity: 1; /* Make it fully visible */
  z-index: 2; /* Ensure it is above the canvas */
}

.textLayer span {
  position: absolute;
  white-space: pre;
  transform-origin: 0 0; /* Proper scaling alignment */
  pointer-events: all; /* Enable text selection */
  cursor: text;
  color: transparent; /* Make text invisible */
  mix-blend-mode: multiply; /* Improve text readability over background */
}